// third-party
import { combineReducers } from 'redux';

import auth from './auth';
import dialog from './dialog';
import race from './race';
import report from './report';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  race,
  report,
  dialog
});

export default reducers;
