import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {
  const navigate = useNavigate();

  if (window.history.length <= 1) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Button
        variant="text"
        onClick={() => {
          navigate(-1);
        }}
        startIcon={<ArrowBack />}>
        Voltar
      </Button>
    </Box>
  );
}
