import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AppBar, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import * as React from 'react';

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`
//   };
// }

export default function CustomTab({ tabs, onChangeTab }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChangeTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <AppBar position="static" color="primary">
          <Box sx={{ borderBottom: 1, color: 'white', borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="lab API tabs example">
              {tabs.map((tab, index) => (
                <Tab
                  color="primary"
                  label={tab.label}
                  value={index}
                  key={index}
                  sx={{
                    '&.MuiButtonBase-root': {
                      color: theme.palette.primary.contrastText,
                      opacity: 0.6
                    },
                    '&.Mui-selected': { color: theme.palette.primary.contrastText, opacity: 1 }
                  }}
                />
              ))}
            </TabList>
          </Box>
        </AppBar>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={index}>
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

CustomTab.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func
};
