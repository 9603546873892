import PersonIcon from '@mui/icons-material/Person';
import PersonIcon2 from '@mui/icons-material/Person2';
import { AppBar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import YouTube from 'react-youtube';

function getHourMinuteAndSeconds(time) {
  const hour = parseInt(time.split(':')[0]);
  const minute = parseInt(time.split(':')[1]);
  const second = parseInt(time.split(':')[2]);

  return hour * 3600 + minute * 60 + second;
}

export default function DialogCompetitorDetails({
  competitor,
  report,
  race,
  firstCompetitor,
  index
}) {
  const [startTimeVideo, setStartTimeVideo] = useState(0);

  const getFirstFinishTime = useCallback(() => {
    if (!report.first_finish_time) return 0;

    return getHourMinuteAndSeconds(report.first_finish_time);
  }, [report]);

  const getTimeCompetitor = useCallback(() => {
    const timeFirstCompetitor = getHourMinuteAndSeconds(firstCompetitor.time);
    const timeCompetitor = getHourMinuteAndSeconds(competitor.time);
    const timeCompetitorReal = timeCompetitor - timeFirstCompetitor;
    return timeCompetitorReal + getFirstFinishTime();
  }, [competitor, race]);

  useEffect(() => {
    if (index === 0) {
      setStartTimeVideo(getFirstFinishTime());
    } else {
      const timeCompetitor = getTimeCompetitor();
      setStartTimeVideo(timeCompetitor);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={2}>
        <Box
          sx={{
            width: '100%',
            height: 200,
            bgcolor: (theme) => theme.palette.common.grey,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {competitor.gender === 'Masculino' ? (
            <PersonIcon sx={{ fontSize: 80 }} />
          ) : (
            <PersonIcon2 sx={{ fontSize: 80 }} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="body1">NUM</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {competitor.number}
            </Typography>
          </Grid>
          {report ? (
            <Grid item>
              <Typography variant="body1">CATEGORIA</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {report.name}
              </Typography>
            </Grid>
          ) : null}

          <Grid item>
            <Typography variant="body1">GÊNERO</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {competitor.gender}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">EQUIPE</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {competitor.team}
            </Typography>
          </Grid>

          {race ? (
            <>
              <Grid item xs={12}>
                <AppBar
                  position="relative"
                  sx={{
                    bgcolor: 'success.main',
                    py: 1,
                    pl: 1,
                    color: 'primary.contrastText',
                    fontWeight: 'bold'
                  }}>
                  RESULTADOS
                </AppBar>
              </Grid>

              <Grid item>
                <Typography variant="body1">GERAL</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.position}º
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1">TEMPO</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.time}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1">RITMO</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.pace}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">VELOCIDADE</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.speed}km/h
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">FAIXA ETÁRIA</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.fx_et}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">FAIXA ETÁRIA</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {competitor.cl_fx === 0 ? '-' : `${competitor.cl_fx}º`}
                </Typography>
              </Grid>
            </>
          ) : null}

          {race?.video_result ? (
            <>
              <Grid item xs={12}>
                <AppBar
                  position="relative"
                  sx={{
                    bgcolor: 'success.main',
                    py: 1,
                    pl: 1,
                    color: 'primary.contrastText',
                    fontWeight: 'bold'
                  }}>
                  LINHA DE CHEGADA
                </AppBar>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex">
                  <YouTube
                    className="container-video"
                    videoId={race.video_result}
                    iframeClassName="video"
                    opts={{
                      // width: 640,
                      // height: 360,
                      playerVars: {
                        start: startTimeVideo ?? 0
                      }
                    }}
                  />
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

DialogCompetitorDetails.propTypes = {
  competitor: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  race: PropTypes.object.isRequired,
  firstCompetitor: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};
