import { DeleteForever, Save } from '@mui/icons-material';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { deleteImage, deleteRace, getRaceDetail, updateRace } from '../store/reducers/race';
import { getReportDetail, updateReportDetail } from '../store/reducers/report';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function RaceDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const confirm = useConfirm();

  const isFetchingRaceDetail = useSelector((state) => state.race.isFetchingRaceDetail);
  const { data: raceDetail, status: raceDetailStatus } = useSelector(
    (state) => state.race.raceDetail
  );

  const reportDetail = useSelector((state) => state.report.reportDetail);
  const isFetchingReportDetail = useSelector((state) => state.report.isFetchingReportDetail);

  const [value, setValue] = useState('1');
  const [videoResult, setVideoResult] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [runDate, setRunDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [startTimeVideo, setStartTimeVideo] = useState('');
  const [firstFinishTime, setFirstFinishTime] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFirstFinishTime('');
    dispatch(getReportDetail(newValue));
  };

  useEffect(() => {
    dispatch(getRaceDetail(id));
  }, [id]);

  useEffect(() => {
    if (raceDetail && raceDetail.status === undefined) {
      setVideoResult(raceDetail.video_result);
      setStartTime(raceDetail.start_time);
      setStartTimeVideo(raceDetail.start_time_video);
      setRunDate(raceDetail.run_date);
    }
  }, [raceDetail]);

  useEffect(() => {
    if (reportDetail) {
      setFirstFinishTime(reportDetail.first_finish_time);
    }
  }, [reportDetail]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, position: 'relative' }}>
          <BackButton />

          {isFetchingRaceDetail || !raceDetail ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {raceDetailStatus === 200 ? (
                <>
                  <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                      size="large"
                      color="error"
                      onClick={async () => {
                        confirm({
                          title: 'Excluir corrida',
                          description: 'Deseja realmente excluir essa corrida?',
                          confirmationText: 'Sim',
                          cancellationText: 'Não'
                        })
                          .then(() =>
                            dispatch(deleteRace({ id: raceDetail.identification, navigate }))
                          )
                          .catch(() => {});
                      }}>
                      <DeleteForever color="common.white" />
                    </IconButton>
                  </Box>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                    align={'left'}>
                    {raceDetail.name}
                  </Typography>
                  <Typography variant={'body2'} align={'left'}>
                    Criado em {moment(raceDetail.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                  <Typography variant={'body2'} align={'left'}>
                    Acesso em{' '}
                    <a
                      target={'_blank'}
                      href={`${location.protocol}//${location.host}/resultado/${raceDetail.identification}`}
                      rel="noreferrer">{`${location.protocol}//${location.host}/resultado/${raceDetail.identification}`}</a>
                  </Typography>

                  <Box>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={6}>
                        {!raceDetail.cover_image_url ? (
                          <FormControl sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                              type="file"
                              id="outlined-adornment-password"
                              onChange={(e) => {
                                setCoverImage(e.target.files[0]);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      dispatch(
                                        updateRace({
                                          id: raceDetail.identification,
                                          payload: { cover_image: coverImage }
                                        })
                                      );
                                    }}
                                    edge="end">
                                    <Save />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label=" Link da capa da corrida"
                            />
                          </FormControl>
                        ) : (
                          <Box
                            sx={(theme) => ({
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              bgcolor: theme.palette.secondary.main,
                              height: '100%',
                              flex: 1,
                              px: 1,
                              pl: 2
                            })}>
                            <Typography
                              sx={{ flexGrow: 1, overflow: 'hidden' }}
                              variant={'body2'}
                              align={'left'}>
                              {raceDetail.cover_image}
                            </Typography>
                            <Tooltip title="Remover imagem">
                              <IconButton
                                onClick={() => {
                                  confirm({
                                    title: 'Excluir imagem capa',
                                    description: 'Deseja realmente excluir essa imagem de capa?',
                                    confirmationText: 'Sim',
                                    cancellationText: 'Não'
                                  })
                                    .then(() =>
                                      dispatch(deleteImage({ uuid: raceDetail.identification }))
                                    )
                                    .catch(() => {});
                                }}>
                                <DeleteForever color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Data da corrida
                          </InputLabel>
                          <ReactInputMask
                            mask="99/99/9999"
                            value={runDate ?? ''}
                            disabled={false}
                            onChange={(e) => setRunDate(e.target.value)}
                            maskChar="_">
                            {(props) => (
                              <OutlinedInput
                                id="outlined-adornment-password"
                                {...props}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        if (runDate.length === 10) {
                                          dispatch(
                                            updateRace({
                                              id: raceDetail.identification,
                                              payload: { run_date: runDate }
                                            })
                                          );
                                        }
                                      }}
                                      edge="end">
                                      <Save />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Data da corrida"
                              />
                            )}
                          </ReactInputMask>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Código do video no youtube
                          </InputLabel>
                          <OutlinedInput
                            value={videoResult}
                            id="outlined-adornment-password"
                            onChange={(e) => setVideoResult(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    dispatch(
                                      updateRace({
                                        id: raceDetail.identification,
                                        payload: { video_result: videoResult }
                                      })
                                    );
                                  }}
                                  edge="end">
                                  <Save />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Código do video no youtube"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Horário de início da prova (H:m:s)
                          </InputLabel>
                          <ReactInputMask
                            mask="99:99:99"
                            value={startTime ?? ''}
                            disabled={false}
                            onChange={(e) => setStartTime(e.target.value)}
                            maskChar="_">
                            {(props) => (
                              <OutlinedInput
                                id="outlined-adornment-password"
                                {...props}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        dispatch(
                                          updateRace({
                                            id: raceDetail.identification,
                                            payload: { start_time: startTime }
                                          })
                                        );
                                      }}
                                      edge="end">
                                      <Save />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Horário de início da prova (H:m:s)"
                              />
                            )}
                          </ReactInputMask>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Horário de início do video (H:m:s)
                          </InputLabel>
                          <ReactInputMask
                            mask="99:99:99"
                            value={startTimeVideo ?? ''}
                            disabled={false}
                            onChange={(e) => setStartTimeVideo(e.target.value)}
                            maskChar="_">
                            {(props) => (
                              <OutlinedInput
                                id="outlined-adornment-password"
                                {...props}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        dispatch(
                                          updateRace({
                                            id: raceDetail.identification,
                                            payload: { start_time_video: startTimeVideo }
                                          })
                                        );
                                      }}
                                      edge="end">
                                      <Save />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Horário de início do video (H:m:s)"
                              />
                            )}
                          </ReactInputMask>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            dispatch(
                              updateRace({
                                id: raceDetail.identification,
                                payload: {
                                  start_time: startTime ?? '',
                                  start_time_video: startTimeVideo ?? '',
                                  run_date: runDate ?? '',
                                  video_result: videoResult ?? '',
                                  cover_image: coverImage ?? ''
                                }
                              })
                            );
                          }}>
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Alert severity="error">Corrida não encontrada</Alert>
                </Box>
              )}
            </>
          )}
        </Paper>
      </Grid>

      {raceDetailStatus === 200 ? (
        <>
          <Grid item xs={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto">
                  {raceDetail.reports.map((report) => (
                    <Tab
                      label={report.name}
                      value={report.identification}
                      key={report.identification}
                    />
                  ))}
                </TabList>
              </Box>
              {raceDetail.reports.map((report, index) => (
                <TabPanel value={report.identification} key={index}>
                  {isFetchingReportDetail || !reportDetail ? (
                    <Box>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <Box mb={3}>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Tempo de vídeo no qual o primeiro competidor(a) passa
                          </InputLabel>
                          <ReactInputMask
                            mask="99:99:99"
                            value={firstFinishTime ?? ''}
                            disabled={false}
                            onChange={(e) => setFirstFinishTime(e.target.value)}
                            maskChar="_">
                            {(props) => (
                              <OutlinedInput
                                id="outlined-adornment-password"
                                {...props}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        dispatch(
                                          updateReportDetail({
                                            id: report.identification,
                                            first_finish_time: firstFinishTime
                                          })
                                        )
                                      }
                                      edge="end">
                                      <Save />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Tempo de vídeo no qual o primeiro competidor(a) passa"
                              />
                            )}
                          </ReactInputMask>
                        </FormControl>
                      </Box>
                      <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">Posição</StyledTableCell>
                              <StyledTableCell align="center">Número</StyledTableCell>
                              <StyledTableCell align="center">Nome</StyledTableCell>
                              <StyledTableCell align="center">Fx.Et.</StyledTableCell>
                              <StyledTableCell align="center">Cl.Fx.</StyledTableCell>
                              <StyledTableCell align="center">Equipe</StyledTableCell>
                              <StyledTableCell align="center">Gênero</StyledTableCell>
                              <StyledTableCell align="center">Ritmo</StyledTableCell>
                              <StyledTableCell align="center">Vel.</StyledTableCell>
                              <StyledTableCell align="center">Tempo</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportDetail.competitors.map((row) => (
                              <StyledTableRow key={row.identification}>
                                <StyledTableCell component="th" align="center" scope="row">
                                  {row.position}°
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.number}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.name}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.age_group?.name}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.cl_fx}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.team?.name}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.gender === 'Male' ? 'Masculino' : 'Feminino'}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.pace}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.speed}/km
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                  {row.time}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
