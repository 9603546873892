import Axios from 'axios';

const requester = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const token = localStorage.getItem('token');

if (token) {
  requester.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

requester.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

requester.interceptors.response.use(null, (err) => {
  const responseURL = err.response.config.url;
  if (err.response.status === 401 && responseURL !== '/api/login') {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
  return Promise.reject(err);
});

export default requester;
