import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LoginPage from './pages/LoginPage';
import RacesPage from './pages/RacesPage';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { useSelector } from 'react-redux';
import DialogCustom from './components/DialogCustom';
import LoggedLayout from './Layout/LoggedLayout';
import PublicLayout from './Layout/PublicLayout';
import RaceDetailsPage from './pages/RaceDetailsPage';
import ResultPage from './pages/ResultPage';
import ResultsPage from './pages/ResultsPage';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#144eb2',
      contrastText: 'white'
    },
    secondary: {
      main: '#f2f2f2',
      contrastText: 'black'
    },
    success: {
      main: '#3db282',
      contrastText: 'white'
    },
    common: {
      green: '#3db282',
      grey: '#f2f2f2'
    }
  }
});

function App() {
  const token = useSelector((state) => state.auth.token) || localStorage.getItem('token');

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Routes>
            <Route path={'/login'} element={<LoginPage />} />
            <Route path="/" element={<PublicLayout />}>
              <Route index element={<ResultsPage />} />
              <Route path="/resultado/:id" element={<ResultPage />} />
            </Route>

            {token ? (
              <Route element={<LoggedLayout />}>
                <Route path="/dashboard" element={<RacesPage />} />
                <Route path="/dashboard/corrida/:id" element={<RaceDetailsPage />} />
              </Route>
            ) : null}

            <Route path="/dashboard" element={<Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
          <DialogCustom />
        </ConfirmProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
