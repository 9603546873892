import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';

function DashboardContent() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header exitApp={false} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}>
        <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default function PublicLayout() {
  return <DashboardContent />;
}
