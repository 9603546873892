import { Category, DirectionsRun } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../components/BackButton';
import CustomTab from '../components/CustomTab';
import DialogCompetitorDetails from '../components/DialogCompetitorDetails';
import ResultTable from '../components/ResultTable';
import TitleResult from '../components/TitleResult';
import { openDialog } from '../store/reducers/dialog';
import { getRaceDetail } from '../store/reducers/race';

export default function ResultPage() {
  // const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const isFetchingRaceDetail = useSelector((state) => state.race.isFetchingRaceDetail);
  const { data: raceDetail, status: raceDetailStatus } = useSelector(
    (state) => state.race.raceDetail
  );

  const [competitors, setCompetitors] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');

  function filterItems(data, fields) {
    return data.filter((val) => {
      return fields.some((field) => {
        if (typeof val[field] === 'string')
          return val[field].toLowerCase().includes(searchFilter.toLowerCase());
        if (typeof val[field] === 'number') {
          return val[field] == searchFilter;
        }
      });
    });
  }

  const onChangeTab = useCallback(() => {
    setSearchFilter('');
  }, []);

  useEffect(() => {
    dispatch(getRaceDetail(id));
  }, [id]);

  useEffect(() => {
    if (raceDetail) {
      const reports = raceDetail.reports;
      let _competitors = [];

      reports.forEach((element) => {
        // find exists competitors
        const existsCompetitors = _competitors.filter(
          (competitor) => competitor.name === element.competitors.name
        );

        if (existsCompetitors.length === 0) {
          _competitors.push(...element.competitors);
        }
      });

      setCompetitors(_competitors);
    }
  }, [raceDetail]);

  return (
    <Grid container spacing={2}>
      {isFetchingRaceDetail ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <BackButton />
          </Grid>
          {raceDetailStatus !== 200 ? (
            <Grid item xs={12}>
              <Alert severity="error">
                Não foi possível localizar este resultado. Ele pode não estar mais disponível.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper sx={{ px: 2, pb: 1 }}>
                  <TitleResult
                    title={raceDetail.name}
                    image={raceDetail.cover_image_url}
                    runDate={raceDetail.run_date}>
                    <>
                      <Box mt={0} display="flex" alignItems="center">
                        <DirectionsRun />
                        <Typography variant="body2">
                          Competidores: <strong>{competitors.length}</strong>
                        </Typography>
                      </Box>
                      <Box mt={0} display="flex" alignItems="center">
                        <Category />
                        <Typography variant="body2">
                          Categorias: <strong>{raceDetail.reports.length}</strong>
                        </Typography>
                      </Box>
                    </>
                  </TitleResult>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <CustomTab
                  onChangeTab={onChangeTab}
                  tabs={[
                    ...raceDetail.reports.map((report) => ({
                      label: report.name,
                      content: (
                        <ResultTable
                          filterForm={
                            <Box display={'flex'} mb={2}>
                              <FormControl
                                sx={{
                                  width: '100%',
                                  backgroundColor: (theme) => theme.palette.common.white
                                }}
                                variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Busque por nome, número, categoria ou equipe
                                </InputLabel>
                                <OutlinedInput
                                  onChange={(e) => {
                                    setSearchFilter(e.target.value);
                                  }}
                                  value={searchFilter}
                                  id="outlined-adornment-password"
                                  label="Busque por nome, número, categoria ou equipe"
                                />
                              </FormControl>
                            </Box>
                          }
                          headCells={[
                            {
                              id: 'position',
                              numeric: false,
                              disablePadding: false,
                              label: 'Posição',
                              format: (value) => `${value}º`
                            },
                            {
                              id: 'number',
                              numeric: false,
                              disablePadding: false,
                              label: 'Número'
                            },
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: 'Nome'
                            },
                            {
                              id: 'fx_et',
                              numeric: false,
                              disablePadding: false,
                              label: 'Fx.Et.'
                            },

                            {
                              id: 'team',
                              numeric: false,
                              disablePadding: false,
                              label: 'Equipe'
                            },
                            {
                              id: 'gender',
                              numeric: false,
                              disablePadding: false,
                              label: 'Gênero'
                            },
                            {
                              id: 'pace',
                              numeric: false,
                              disablePadding: false,
                              label: 'Ritmo'
                            },
                            {
                              id: 'speed',
                              numeric: false,
                              disablePadding: false,
                              label: 'Velocidade',
                              format: (value) => `${value}km/h`
                            },
                            {
                              id: 'time',
                              numeric: false,
                              disablePadding: false,
                              label: 'Tempo'
                            }
                          ]}
                          rows={filterItems(
                            report.competitors.map((competitor, index) => ({
                              ...competitor,
                              gender: competitor.gender === 'Male' ? 'Masculino' : 'Feminino',
                              team: competitor.team ? competitor.team.name : '',
                              fx_et: competitor.age_group ? competitor.age_group.name : '',
                              position: competitor.position,
                              number: parseInt(competitor.number),
                              speed: parseFloat(competitor.speed.replace(',', '.')),
                              onClick: (row) => {
                                dispatch(
                                  openDialog({
                                    title: row.name,
                                    content: (
                                      <DialogCompetitorDetails
                                        competitor={row}
                                        firstCompetitor={report.competitors[0]}
                                        index={index}
                                        report={report}
                                        race={raceDetail}
                                      />
                                    )
                                  })
                                );
                              }
                            })),
                            ['name', 'number', 'fx_et', 'team']
                          )}
                        />
                      )
                    })),
                    {
                      label: 'Atletas',
                      content: (
                        <ResultTable
                          filterForm={
                            <Box display={'flex'} mb={2}>
                              <FormControl
                                sx={{
                                  width: '100%'
                                }}
                                variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Busque por nome, faixa etária, equipe ou gênero
                                </InputLabel>
                                <OutlinedInput
                                  sx={{ backgroundColor: (theme) => theme.palette.common.white }}
                                  onChange={(e) => {
                                    setSearchFilter(e.target.value);
                                  }}
                                  value={searchFilter}
                                  id="outlined-adornment-password"
                                  label="Busque por nome, faixa etária, equipe ou gênero"
                                />
                              </FormControl>
                            </Box>
                          }
                          headCells={[
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: 'Nome'
                            },
                            {
                              id: 'fx_et',
                              numeric: false,
                              disablePadding: false,
                              label: 'Fx.Et.'
                            },

                            {
                              id: 'team',
                              numeric: false,
                              disablePadding: false,
                              label: 'Equipe'
                            },
                            {
                              id: 'gender',
                              numeric: false,
                              disablePadding: false,
                              label: 'Gênero'
                            }
                          ]}
                          rows={filterItems(
                            competitors.map((competitor, index) => ({
                              ...competitor,
                              gender: competitor.gender === 'Male' ? 'Masculino' : 'Feminino',
                              team: competitor.team ? competitor.team.name : '---',
                              fx_et: competitor.age_group ? competitor.age_group.name : '---',
                              onClick: (row) => {
                                dispatch(
                                  openDialog({
                                    title: row.name,
                                    content: (
                                      <DialogCompetitorDetails
                                        competitor={row}
                                        index={index}
                                        firstCompetitor={competitors[0]}
                                        report={null}
                                        race={null}
                                      />
                                    )
                                  })
                                );
                              }
                            })),
                            ['name', 'fx_et', 'team', 'gender']
                          )}
                        />
                      )
                    }
                  ]}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
