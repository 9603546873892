import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requester from '../../requester';
import { toast } from 'react-toastify';

const initialState = {
  isFetchingReportDetail: false,
  reportDetail: null
};

export const getReportDetail = createAsyncThunk(
  'race/getReportDetail',
  async (id, { rejectWithValue, fulfillWithValue }) => {
    return requester
      .get(`/api/v1/reports/${id}`)
      .then((res) => {
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      });
  }
);

export const updateReportDetail = createAsyncThunk(
  'race/updateReportDetail',
  async ({ id, ...data }, { rejectWithValue, fulfillWithValue }) => {
    const loading = toast.loading('Atualizando dados...');
    return requester
      .patch(`/api/v1/reports/${id}`, data)
      .then((res) => {
        toast.success('Dados atualizados com sucesso!');
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  extraReducers: {
    [getReportDetail.rejected]: (state) => {
      state.isFetchingReportDetail = false;
      state.reportDetail = null;
    },
    [getReportDetail.pending]: (state) => {
      state.isFetchingReportDetail = true;
    },
    [getReportDetail.fulfilled]: (state, action) => {
      state.isFetchingReportDetail = false;
      state.reportDetail = action.payload.data;
    }
  }
});

const { reducer } = reportSlice;
export default reducer;
