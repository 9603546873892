// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import requester from '../../requester';
// import authService from '../../services/AuthService';

const initialState = {
  loading: false,
  logoutInProgress: false,
  token: null
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue, fulfillWithValue }) => {
    const loading = toast.loading('Autenticando...');

    return requester
      .post('/api/login', { email, password })
      .then((res) => {
        toast.dismiss(loading);
        toast.success('Autenticado com sucesso!');

        // save token in storage
        localStorage.setItem('token', res.data.token);
        // navigate('/dashboard');

        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Usuário ou senha inválidos!');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    const loading = toast.loading('Saindo...');

    return requester
      .post('/api/v1/logout')
      .then((res) => {
        toast.dismiss(loading);
        toast.success('Deslogado com sucesso!');

        // save token in storage
        localStorage.removeItem('token');
        window.location.href = '/login';

        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao tentar deslogar, tente novamente!');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.rejected]: (state) => {
      state.loading = false;
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.token = action.payload.data.token;
    },
    [logout.rejected]: (state) => {
      state.logoutInProgress = false;
    },
    [logout.pending]: (state) => {
      state.logoutInProgress = true;
    },
    [logout.fulfilled]: (state) => {
      state.logoutInProgress = false;
      state.token = null;
    }
  }
});

const { reducer } = authSlice;
export default reducer;
