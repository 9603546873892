import { DeleteForever, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FileUpload from 'react-mui-fileuploader';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { createRace, getRaces } from '../store/reducers/race';

export default function RacesPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileUploadRef = useRef(null);

  const location = useLocation();

  const [file, setFile] = useState(null);
  const [gender, setGender] = useState(null);
  const [contextFileUpload, setContextFileUpload] = useState(null);

  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);

  const { data: races, status: racesStatus } = useSelector((state) => state.race.races);
  const isFetchingRaces = useSelector((state) => state.race.isFetchingRaces);
  const isCreatingRace = useSelector((state) => state.race.isCreatingRace);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPage(params.get('page') || 1);
    setSearch(params.get('name') || '');
    dispatch(getRaces({ name: params.get('name') ?? '', page: params.get('page') }));
  }, [location.search]);

  const handleFileUploadError = () => {};

  const handleFilesChange = (files) => {
    setFile(files[0]);
  };

  const resetForm = useCallback(() => {
    contextFileUpload.removeFile();
    setGender(null);
  }, [contextFileUpload]);

  useEffect(() => {
    if (file) {
      const fileName = file.name;

      // contains feminino

      if (fileName.toLowerCase().includes(['fem'])) {
        setGender('female');
      }

      if (fileName.toLowerCase().includes(['masc'])) {
        setGender('male');
      }
    }
  }, [file]);

  const createRaceCallback = useCallback(() => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('gender', gender);

    dispatch(createRace({ payload: formData, resetForm: resetForm }));
  }, [file, gender]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom align={'left'}>
            Nova corrida
          </Typography>
          <Typography variant={'body2'} color="gray" align={'left'}>
            Selecione o arquivo com os dados da corrida, lembre-se que o arquivo deve ser no formato
            XLS. Caso você esteja adicionando novas categorias ou atualizando os dados de uma
            categoria, lembre-se de verificar se o nome da corrida contida no arquivo é a mesma que
            a existente no sistema.
          </Typography>
          <Box>
            <FileUpload
              ref={(ref) => (fileUploadRef.current = ref)}
              getBase64={false}
              multiFile={false}
              disabled={false}
              title={false}
              header="[Solte o arquivo aqui]"
              leftLabel="ou"
              rightLabel="Selecione o arquivo"
              buttonLabel="Clique aqui"
              buttonRemoveLabel="Remover tudo"
              maxFileSize={5000000000}
              maxUploadFiles={1}
              maxFilesContainerHeight={300}
              errorSizeMessage={'O tamanho do arquivo é muito grande'}
              onFilesChange={handleFilesChange}
              onError={handleFileUploadError}
              BannerProps={{
                elevation: 0,
                variant: 'outlined',
                sx: { background: theme.palette.common.green }
              }}
              onContextReady={(context) => {
                setContextFileUpload(context);
              }}
              ContainerProps={{
                elevation: 0,
                variant: 'outlined',
                sx: { border: 0 }
              }}
              PlaceholderImageDimension={{
                xs: { width: 128, height: 128 },
                sm: { width: 128, height: 128 },
                md: { width: 164, height: 164 },
                lg: { width: '100%', height: 180 }
              }}
            />

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Gênero</InputLabel>
              <Select
                disabled={!file || isCreatingRace}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Gênero"
                onChange={(e) => setGender(e.target.value)}>
                <MenuItem value={'male'}>Masculino</MenuItem>
                <MenuItem value={'female'}>Feminino</MenuItem>
              </Select>
            </FormControl>

            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => createRaceCallback()}
              disabled={!file || isCreatingRace || !gender}
              fullWidth
              erro
              color="success">
              {isCreatingRace && <CircularProgress size={20} color={'inherit'} />}
              {!isCreatingRace && 'Importar registros'}
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom align={'left'}>
            Corridas
          </Typography>
          <Typography variant={'body2'} color="gray" align={'left'}>
            Lista de corridas
          </Typography>

          <FormControl sx={{ width: '100%', my: 2 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={search}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate({
                    pathname: '/dashboard',
                    search: createSearchParams({
                      name: search
                    }).toString()
                  });
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      navigate({
                        pathname: '/dashboard',
                        search: createSearchParams({
                          name: search
                        }).toString()
                      });
                    }}
                    edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          {isFetchingRaces && (
            <Box display={'flex'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          )}

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {!isFetchingRaces && (
              <>
                {racesStatus !== 200 && (
                  <Typography color={'error'}>Não foi possível carregar as corridas</Typography>
                )}
                {races?.data?.map((val, key) => (
                  <ListItemButton
                    onClick={() => {
                      navigate(`/dashboard/corrida/${val.identification}`);
                    }}
                    alignItems="flex-start"
                    key={key}
                    divider
                    secondaryAction={
                      <IconButton edge="end" color="error" aria-label="comments">
                        <DeleteForever />
                      </IconButton>
                    }>
                    <ListItemText
                      primary={val.name}
                      primaryTypographyProps={{ color: 'primary', fontWeight: 600 }}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary">
                            {moment(val.created_at).format('DD/MM/YYYY HH:mm')}
                          </Typography>
                        </>
                      }
                    />
                  </ListItemButton>
                ))}

                {racesStatus === 200 && (
                  <Box mt={2}>
                    <Pagination
                      count={races.last_page ?? 0}
                      color="primary"
                      page={parseInt(page)}
                      onChange={(e, page) => {
                        const params = {};

                        if (search) {
                          params.name = search;
                        }

                        navigate({
                          pathname: '/dashboard',
                          search: createSearchParams({
                            ...params,
                            page
                          }).toString()
                        });
                      }}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    />
                  </Box>
                )}
              </>
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
}
