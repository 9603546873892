import { CalendarMonth } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function TitleResult({ title, image, runDate, children, maxWidth = 220 }) {
  return (
    <Grid
      container
      spacing={2}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center'
        }
      })}>
      {image ? (
        <Grid item>
          <Box sx={{ maxWidth }}>
            <img src={image} alt={title} />
          </Box>
        </Grid>
      ) : null}

      <Grid item>
        <Typography variant={'h6'} sx={{ justifyContent: 'flex-start', textAlign: 'left' }}>
          {title}
        </Typography>
        {runDate ? (
          <Box display={'flex'} alignItems="center">
            <Tooltip title="Evento realizado em">
              <Box display={'flex'} alignItems="center">
                <CalendarMonth color="gray" />
                <Typography variant="body2" color="gray">
                  {runDate}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        ) : null}

        {children}
      </Grid>
    </Grid>
  );
}

TitleResult.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  runDate: PropTypes.string.isRequired,
  children: PropTypes.node,
  maxWidth: PropTypes.number
};
