import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import requester from '../../requester';

const initialState = {
  isFetchingRaces: false,
  isFetchingRaceDetail: false,
  races: {
    data: [],
    status: null
  },
  raceDetail: {
    data: null,
    status: null
  },
  isCreatingRace: false
};

export const getRaces = createAsyncThunk(
  'race/getRaces',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    params = { ...params, page: params.page ?? 1 };
    const searchParams = new URLSearchParams(params).toString();

    return requester
      .get(`/api/v1/races?${searchParams}`)
      .then((res) => {
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      });
  }
);

export const getRaceDetail = createAsyncThunk(
  'race/getRaceDetail',
  async (id, { rejectWithValue, fulfillWithValue }) => {
    return requester
      .get(`/api/v1/races/${id}`)
      .then((res) => {
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Ops... Não foi possível encontrar a corrida.');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      });
  }
);

export const createRace = createAsyncThunk(
  'race/createRace',
  async ({ payload, resetForm }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    return requester
      .post(`/api/v1/races`, payload)
      .then((res) => {
        toast.success('Registros importados com sucesso!');
        dispatch(getRaces({ page: 1 }));
        resetForm();
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(
          'Ops... Não foi possível importar os registros. Verifique o formato do arquivo e tente novamente!'
        );
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      });
  }
);

export const updateRace = createAsyncThunk(
  'race/updateRace',
  async ({ id, payload }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Atualizando dados...');

    const formdata = new FormData();

    Object.keys(payload).forEach((key) => {
      formdata.append(key, payload[key]);
    });

    return requester
      .post(`/api/v1/races/${id}`, formdata)
      .then((res) => {
        toast.success('Dado atualizado com sucesso!');
        dispatch(getRaceDetail(id));
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(
          'Ops... Não foi possível atualizar o dado. Verifique o formato do arquivo e tente novamente!'
        );
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const deleteRace = createAsyncThunk(
  'race/deleteRace',
  async ({ id, navigate }, { rejectWithValue, fulfillWithValue }) => {
    const loading = toast.loading('Removendo corrida...');

    return requester
      .delete(`/api/v1/races/${id}`)
      .then((res) => {
        toast.success('Corrida removida com sucesso!');
        navigate('/dashboard');
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(
          'Ops... Não foi possível atualizar o dado. Verifique o formato do arquivo e tente novamente!'
        );
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const deleteImage = createAsyncThunk(
  'race/deleteRace',
  async ({ uuid }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo corrida...');

    return requester
      .delete(`/api/v1/races/${uuid}/image`)
      .then((res) => {
        toast.success('Imagem removida com sucesso!');
        dispatch(getRaceDetail(uuid));
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Ops... Não foi possível atualizar o dado');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const raceSlice = createSlice({
  name: 'race',
  initialState,
  extraReducers: {
    [getRaces.rejected]: (state, action) => {
      state.isFetchingRaces = false;
      state.races = { data: [], status: action.payload?.status ?? 500 };
    },
    [getRaces.pending]: (state) => {
      state.isFetchingRaces = true;
    },
    [getRaces.fulfilled]: (state, action) => {
      state.isFetchingRaces = false;
      state.races = action.payload;
    },
    [getRaceDetail.rejected]: (state, action) => {
      state.isFetchingRaceDetail = false;
      state.races = { data: null, status: action.payload.status };
    },
    [getRaceDetail.pending]: (state) => {
      state.isFetchingRaceDetail = true;
    },
    [getRaceDetail.fulfilled]: (state, action) => {
      state.isFetchingRaceDetail = false;
      state.raceDetail = action.payload;
    },
    [createRace.rejected]: (state) => {
      state.isCreatingRace = false;
    },
    [createRace.pending]: (state) => {
      state.isCreatingRace = true;
    },
    [createRace.fulfilled]: (state) => {
      state.isCreatingRace = false;
    }
  }
});

const { reducer } = raceSlice;
export default reducer;
