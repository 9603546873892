import { Search } from '@mui/icons-material';
import {
  Alert,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
  Paper
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import TitleResult from '../components/TitleResult';
import { getRaces } from '../store/reducers/race';

export default function ResultsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isFetchingRaces = useSelector((state) => state.race.isFetchingRaces);
  const { data: races, status: racesStatus } = useSelector((state) => state.race.races);

  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPage(params.get('page') || 1);
    setSearch(params.get('name') || '');
    dispatch(getRaces({ name: params.get('name') ?? '', page: params.get('page') }));
  }, [location.search]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: (theme) => theme.palette.common.white
            }}
            variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={search}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate({
                    pathname: '/',
                    search: createSearchParams({
                      name: search
                    }).toString()
                  });
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      navigate({
                        pathname: '/',
                        search: createSearchParams({
                          name: search
                        }).toString()
                      });
                    }}
                    edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              label="Buscar"
            />
          </FormControl>
        </Grid>

        {isFetchingRaces ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {racesStatus !== 200 && (
              <Grid item xs={12}>
                <Alert severity="error">
                  Não foi possível carregar os resultados, tente novamente em instantes.
                </Alert>
              </Grid>
            )}

            {races.data?.map((val, index) => (
              <Grid key={index} item xs={12}>
                <Paper
                  onClick={() => {
                    navigate(`/resultado/${val.identification}`);
                  }}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    px: 2,
                    pb: 2,
                    ':hover': {
                      backgroundColor: theme.palette.grey[50]
                    }
                  })}>
                  <TitleResult
                    maxWidth={140}
                    title={val.name}
                    image={val.cover_image_url}
                    runDate={val.run_date}
                  />
                </Paper>
              </Grid>
            ))}

            {racesStatus === 200 && (
              <Grid item xs={12}>
                <Pagination
                  count={races.last_page ?? 0}
                  color="primary"
                  page={parseInt(page)}
                  onChange={(e, page) => {
                    const params = {};

                    if (search) {
                      params.name = search;
                    }

                    navigate({
                      pathname: '/',
                      search: createSearchParams({
                        ...params,
                        page
                      }).toString()
                    });
                  }}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
